<template>
  <v-tooltip :[position]="true" :color="color" :max-width="maxWidth">
    <template #activator="{ on, attrs }">
      <div class="container_activator" v-bind="attrs" v-on="on">
        <slot />
      </div>
    </template>
    <slot name="message" />
  </v-tooltip>
</template>

<script>
export default {
  name: "HelperMessage",
  props: {
    color: {
      type: String,
      default: "primary"
    },
    position: {
      type: String,
      default: "top"
    },
    maxWidth: {
      type: [String, Number],
      default: 400
    }
  },
  data: () => ({})
};
</script>

<style scoped>
.container_activator {
  display: inline-block;
}
</style>
