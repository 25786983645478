import request from "../helpers/request";
import { requestV2 } from "../helpers/request";

export function CreateUser(body) {
  try {
    return new Promise((resolve, reject) => {
      request("/user/create", body)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function GetContactList() {
  try {
    return new Promise((resolve, reject) => {
      request("/Contacts/List", {})
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function GetContactLeadDuplicate({ name, email, phone, isBrex }) {
  const userToken = localStorage.getItem("token");
  const payload = JSON.parse(atob(userToken.split(".")[1]));
  try {
    return new Promise((resolve, reject) => {
      request("/contactLead/checkDuplicateContact", {
        name,
        email,
        phone,
        isBrex,
        real_estate_group_id: payload.access_group
      })
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function GetContactListAllocation() {
  const userToken = localStorage.getItem("token");
  const payload = JSON.parse(atob(userToken.split(".")[1]));
  try {
    return new Promise((resolve, reject) => {
      request("/contact/listAllocation", {
        real_estate_group_id: payload.access_group
      })
        .then(response => {
          resolve({
            response
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function CreateContactListLocation(data) {
  const userToken = localStorage.getItem("token");
  const payload = JSON.parse(atob(userToken.split(".")[1]));
  try {
    return new Promise((resolve, reject) => {
      request("/contact/createAllocation", {
        name: data.name,
        source_name: data.source_name,
        marketing_assigned: data.marketing_assigned,
        real_estate_group_id: payload.access_group,
        real_estate_develop_id: data.realEstateDevelopId,
        contact_list: data.contact_list
      })
        .then(response => {
          resolve({
            response
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function UpdateContactListAllocation(data) {
  try {
    return new Promise((resolve, reject) => {
      request("/contact/updateAllocation", data)
        .then(response => {
          resolve({
            response
          });
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export function updateContactCarouselBrokerById(data) {
  try {
    return new Promise((resolve, reject) => {
      request("/contactBrokers/updateContactBrokerById", data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function updateContactCarouselDeveloperById(data) {
  try {
    return new Promise((resolve, reject) => {
      request("/contactDevelopers/updateContactDeveloperById", data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function updateContactCarouselAdminById(data) {
  try {
    return new Promise((resolve, reject) => {
      request("/contactAdmins/updateContactAdminsById", data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}
export function multiAssignedLeadstoContact(data) {
  try {
    return new Promise((resolve, reject) => {
      request("/contactLead/multiAssigned", data)
        .then(response => {
          resolve(response.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  } catch (err) {
    return Promise.reject(err);
  }
}

export async function updateTimeRangeByContact(body) {
  const res = await requestV2({
    endpoint: "/contact/allocation/update-time-range",
    bodyRequest: body
  });
  if (!res.Data) return res.Message;

  return res;
}

export async function getLogsByAllocation(id) {
  const res = await requestV2({
    endpoint: `/contact/allocation/logs/${id}`
  });
  if (!res.Data) return res.Message;

  return res;
}
